<script>
import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";
import {Modal} from "bootstrap";
// import moment from "moment";
export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      payModal:null,
      page: 1,
      tot_pages: 0,
      limit: 20,
      search: "",
      selectedCustomer: "",
      selectedCurrency: "",
      pay_amount: 0,
      invoices: '',
      matchedCurrencies: [],
      matchedAccounts: [],
      cash_accounts: [],
      unearned: 0,
      receivable: 0,
      customer: [],
      currencies: [],
      supplier: {},
      customerTotal: 0,
      addAsset: [{}],
      addPayAccounts: [{}],
      created: '',
    };
  },
  computed: {
    customer_currencies(){
      if(this.selectedCustomer?.currency_id == this.currencies?.find(c=>c.is_functional)?.id){
        return [this.currencies?.find(c=>c.is_functional)]
      }
      else if(this.selectedCustomer){
        return [this.currencies?.find(c=>c.is_functional),this.currencies?.find(c=>c.id==this.selectedCustomer.currency_id)]
      }
      else{
        return []
      }
    },
    totalAddPayAccounts(){
      return this.addPayAccounts.filter(account=>account.value)
          .reduce((sum,account)=>{
            if(this.selectedCurrency?.id != account.cash_account.currency_id && this.selectedCurrency?.is_functional){
              sum += parseFloat(account.value) * parseFloat(account.cash_account.current_rate)
            }
            else if(this.selectedCurrency?.id !=account.cash_account.currency_id && account.cash_account.is_functional){
              sum += parseFloat(account.value) / parseFloat(this.selectedCurrency?.current_rate)
            }
            else if(this.selectedCurrency?.id != account.cash_account.currency_id){
              sum += parseFloat(account.value) * parseFloat(  this.selectedCurrency?.current_rate) / parseFloat(account.cash_account.current_rate)
            }
            else{
              sum += parseFloat(account.value)
            }
            return sum
          },0)
    }
  },
  methods: {
    getCurrencies() {
      this.http.get("currencies/active").then((res) => {
        if (res.status) {
          this.currencies = res.data;
        }
      });
    },
    accountSearch(query){
      this.matchedAccounts = this.cash_accounts.filter(account=>
          (
              account.name.toLowerCase().includes(query.toLowerCase()) ||
              account.name_en.toLowerCase().includes(query.toLowerCase())
          ) &&
          !this.addPayAccounts.some(payAccount=>payAccount?.cash_account?.cash_account_id==account.cash_account_id)

      )
      console.log("this.matchedAccounts",this.matchedAccounts)
    },
    validateCashAccount(payAccount){
      if(parseFloat(payAccount.cash_account.balance)==0){
        payAccount.cash_account = ""
      }
    },
    searchCustomer(search) {
      console.log(search);
      this.tot_pages = 0;
      this.http
        .post("agents/search-customer", {
          search,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.customer = res.data
        });
    },
    searchCurrency(search) {
      this.matchedCurrencies = this.customer_currencies.filter(currency => currency.name.toLowerCase().includes(search.toLowerCase()) || currency.name_en.toLowerCase().includes(search.toLowerCase() ))
    },
    showLabel(customer){
      return customer.code + " " + customer.name
    },
    showAccountLabel(account){
      console.log("ACCOUNT",account)
      return (this.$i18n.locale == "ar"
              ? account?.name
              : account?.name_en) + " - " +
          this.processCurrency(account,account.balance)
    },
    showCurrencyLabel(currency){
      return this.$i18n.locale=='ar' ? currency?.name : currency?.name_en
    },
    selectCustomer(page) {
      if(!this.selectedCurrency)
        return
      const id = this.selectedCustomer.id;
      console.log(this.selectedCustomer);
      this.http
        .post("sales/invoices", {
          limit: this.limit,
          page,
          currency_id:this.selectedCurrency.id,
          id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.invoices = res.invoices;
          this.unearned = parseFloat(res.unearned)
          this.receivable = parseFloat(res.receivable)
          this.customerTotal = res.tot;
        });
    },
    payInvoices() {
      console.log(this.addPayAccounts,this.selectedCustomer);
      this.http
        .post("sales/payment-made", {
          accounts: this.addPayAccounts,
          agent_id: this.selectedCustomer?.id,
          currency_id:this.selectedCurrency.id,
          created:this.created
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            this.addPayAccounts = [{}];
            this.selectCustomer(this.page);
            this.getSaleConfigurations();
            this.payModal.hide();
          }
        });
    },
    getSaleConfigurations() {
      this.http
          .get("sales/configurations")
          .then((res) => {
            this.cash_accounts = res.cash_accounts;
          });
    },
  },
  mounted() {
    this.payModal = new Modal("#payModal")
    this.getCurrencies();
    this.getSaleConfigurations();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.payments.sub.payment_made')"
    />

    <div
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      class="form-group row mb-3"
    >
      <div class="row">
        <div class="col-12 col-md-4">
          <label
              class="col-md-2.5 col-form-label"
              for="userName"
              style="font-size: 16px; font-weight: bolder"
          >{{ $t("sale_payments.payment_made_search") }}</label
          >
          <AutoComplete
            v-model="selectedCustomer"
            :complete-on-focus="true"
            :suggestions="customer"
            @item-select="customer_currencies.length==1 ? selectedCurrency=customer_currencies[0] : selectedCurrency='' ; invoices=''"
            @complete="searchCustomer($event.query)"

            :optionLabel="showLabel"
            style="color: #333 !important; border-radius: 10px !important;width:100%"
          />
          <br>
        </div>
        <div class="col-12 col-md-4">
          <label
              class="col-md-2.5 col-form-label"
              for="userName"
              style="font-size: 16px; font-weight: bolder"
          >{{$t("purchases.currency")}}</label
          >
          <AutoComplete
              v-model="selectedCurrency"
              :complete-on-focus="true"
              :suggestions="matchedCurrencies"
              @complete="searchCurrency($event.query)"

              :optionLabel="showCurrencyLabel"
              style="color: #333 !important; border-radius: 10px !important;width:100%"
          />
        </div>
        <div class="col d-flex align-items-end">

            <button
              @click="selectCustomer(1)"
              type="button"
              :disabled="!selectedCurrency || !selectedCustomer"
              class="btn btn-primary mb-sm-1"
            >
              {{ $t("popups.search") }}
            </button>

        </div>
      </div>
    </div>

    <div class="row" v-if="invoices">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">{{ $t("agents.id") }}</th>
                  <th scope="col">{{ $t("agents.name") }}</th>
                  <th scope="col">{{ $t("payment_made.total_invoices") }}</th>
                  <th scope="col">{{ $t("payment_made.total_unearned") }}</th>
                  <th scope="col">{{ $t("agents.receivable_account") }}</th>
                  <th scope="col">{{ $t("agents.phone") }}</th>
                  <!-- <th scope="col">{{ $t("payment_made.created") }}</th> -->
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ selectedCustomer?.code }}</td>
                  <td>
                    {{
                      selectedCustomer?.name
                    }}
                  </td>
                  <td>
                    {{
                      parseFloat(
                        customerTotal ? customerTotal : 0
                      ).toLocaleString()
                    }}
                  </td>
                  <td
                    :style="{
                      color:
                        parseFloat(unearned) < 0
                          ? '#26d526'
                          : parseFloat(unearned) > 0
                          ? 'green'
                          : '',
                    }"
                  >
                    {{ parseFloat(unearned).toLocaleString() }}
                  </td>
                  <td
                    :style="{
                      color:
                        parseFloat(receivable) < 0
                          ? '#26d526'
                          : parseFloat(receivable) > 0
                          ? 'red'
                          : '',
                    }"
                  >
                    {{ processCurrency(selectedCurrency,receivable,true) }}
                  </td>
                  <td>{{ selectedCustomer?.tel }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-between">
            <div></div>
            <div>
              <button
                v-if="invoices.length > 0"
                @click="payModal.show"
                class="btn btn-primary"
              >
                {{ $t("payment_made.pay_invoices") }}
              </button>
              <button
                v-if="invoices.length <= 0"
                @click="payModal.show"
                class="btn btn-primary"
              >
                {{ $t("payment_made.loan_spplier") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="invoices.length > 0">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <!-- <th scope="col">{{ $t("payment_made.name") }}</th> -->
                  <th scope="col">{{ $t("payment_made.invoice_amount") }}</th>
                  <th scope="col">{{ $t("payment_made.paid") }}</th>
                  <th scope="col">{{ $t("payment_made.remaining") }}</th>
                  <th scope="col">{{ $t("payment_made.created") }}</th>
                  <th scope="col">{{ $t("payment_made.status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(app) in invoices"
                  :key="app"
                  class=""
                >
                  <td>#{{ app.id }}</td>
                  <td>{{ processCurrency(app,(app.is_functional == null? app?.total_amount :  app?.total_amount_in_other_currency),true) }}</td>
                  <td>{{ processCurrency(app, (app?.paid_amount? app?.paid_amount : 0),true) }}</td>
                  <td>
                    {{
                      (
                        parseFloat(app.is_functional == null? app?.total_amount :  app?.total_amount_in_other_currency) -
                        parseFloat(app?.paid_amount? app?.paid_amount : 0)
                      ).toLocaleString()
                    }}
                  </td>
                  <td>{{ app?.so_time?.split("T")[0] }}</td>
                  <td>
                    {{
                      parseFloat(app.currency_id? app?.total_amount :  app?.total_amount_in_other_currency) == parseFloat(app?.paid_amount)
                        ? $i18n.locale == "ar"
                          ? "تم الدفع"
                          : "Paid"
                        : $i18n.locale == "ar"
                        ? "لم يتم الدفع"
                        : "Unpaid"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- end  table -->

    <!--   Sale  Pagination     -->
    <ul
      v-if="invoices.length > 0"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="selectCustomer(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="selectCustomer(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == tot_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="selectedCustomer(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>

    <!--Start Pay Modal-->
    <div
      class="modal fade"
      id="payModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="payInvoices()">
            <div class="modal-header">
              <div class="d-flex justify-content-around">
                <h5 class="modal-title" id="exampleModalLabel">

                </h5>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style="margin: 0"
              ></button>
            </div>

            <div class="modal-body">
              <div class="">
                <div class="mt-3">
                  <div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <label for="asset_id">{{
                            $t("sales.chose_acccount")
                          }}</label>
                      </div>
                      <button type="button" @click="addPayAccounts.push({})" class="btn">
                        <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                      </button>
                    </div>
                    <div v-for="(payAccount, aIndex) in addPayAccounts" :key="payAccount">
                      <div class="purchase-container d-flex mb-sm-2">
                        <!--                          @item-select="selectCustomer"-->
                        <div class="row w-100">
                          <div class="col-12 col-md-5 mb-2 mb-md-1">
                            <AutoComplete
                                v-model="payAccount.cash_account"
                                :suggestions="matchedAccounts"

                                @complete="accountSearch($event.query)"
                                :complete-on-focus="true"
                                :optionLabel="showAccountLabel"
                                style="color: #333 !important;width:100%" />
                          </div>
                          <div class="col-12 col-md-5 mb-2 mb-md-1">
                            <currency-input :disabled="!payAccount.cash_account" v-model="payAccount.value"
                                            class="form-control mb-2 mx-1" :placeholder="$t('sales.amount')" ></currency-input>
                          </div>
                          <div class="col-12 col-md-2 mb-2 mb-md-1">
                            <button type="button" role="button" @click="addPayAccounts.splice(aIndex, 1)"
                                    :disabled="addPayAccounts.length == 1" class="btn btn-danger">
                              <i class="bx bx-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>{{$t("sales.total")}}: {{processCurrency(selectedCurrency,totalAddPayAccounts,true)}}</h6>
                  </div>
                  <div class="purchase-container d-flex mb-sm-2">
                        <div class="row w-100">
                          <!-- Date -->
                          <div class="col-12 col-md-5 mb-2 mb-md-1" style="margin: 10px 0;">
                            <input type="datetime-local" v-model="created" class="form-control mx-1"
                                    :placeholder="$t('sales.created')" />
                          </div>
                        </div>
                  </div>

                </div>
                <hr>
                <div v-if="unearned>0">
                  {{$t("sales.unearned_from_customer")}}: {{processCurrency(selectedCurrency,unearned,true)}}
                </div>
                <div v-if="receivable>0">
                  {{$t("sales.recivable_to_customer")}}: {{processCurrency(selectedCurrency,receivable,true)}}
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button
                :disabled="
                  totalAddPayAccounts==0
                "
                type="submit"
                class="btn btn-primary"
              >
                {{ $t("popups.pay") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Pay Modal-->


  </Layout>
</template>
